:root {
  --spc: 5px;
  --spacing: 5px; /* alias */

  --primary: hsl(210, 100%, 45%);
  --primary-dark: #0057af;
  --primary-light: #f0f4f9;
  --secondary: #ff6e0d;
  --text-label: #494949;
  --white: hsl(210, 70%, 99%);
  --black: hsl(0, 100%, 0%);
  --grey-lighter: #f5f5f5;
  --grey-light: #ddd;
  --grey-dark: #b5b5b5;
  --grey-darker: #575755;

  --success: #3ea798;
  --error: #ff003b;
  --error-light: #ffe3e9;

  --new-power-bi: #3f639f;
  --power-bi: #f2c80f;
  --excel: #207245;
  --excel-light: #107c41;
  --studio: #0081be;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*  resets */
body {
  color: var(--black);
}
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
  font-family: Open Sans, sans-serif;
}

/* Dialog  */
.MuiDialog-paper {
  padding: calc(var(--spc) * 4);
  border-radius: 4px;
}
/* End Dialog  */

.center-content {
  display: grid;
  place-items: center;
}
.center-content.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(210, 70%, 99%, 0.7);
}

.fit-parent {
  height: 100%;
  vertical-align: middle;
}

.bar {
  width: calc(var(--spc) * 10);
  height: var(--spc);
  margin: calc(4 * var(--spc)) 0;
}
.bar.bi {
  background: var(--new-power-bi);
}
.bar.excel {
  background: var(--excel);
}
.bar.studio {
  background: var(--studio);
}

.MuiButtonBase-root.Mui-focusVisible {
  border: 2px solid rgba(0, 0, 0, 0.23);
}

/* validation code entry */
fieldset#validation-code input {
  text-align: center;
  width: 40px;
  height: 55px;
  font-size: 35px;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid var(--grey-dark);
  color: var(--primary);
}

/* ad-hoc style overrides for Plans page */
/* Temp, refactor */
.plan-desc-list-bi {
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 20px;
}
.plan-desc-list-bi li {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  position: relative;
}
.plan-desc-list-bi li::before {
  content: ". ";
  font-size: 2.5rem;
  color: var(--new-power-bi);
  line-height: 0;

  position: absolute;
  top: -2px;
  left: -20px;
}

.plan-desc-list-excel {
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 18px;
}
.plan-desc-list-excel li {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  position: relative;
  margin-bottom: 10px;
}

.plan-desc-list-excel li::before {
  content: ". ";
  font-size: 2.5rem;
  color: var(--excel-light);
  line-height: 0;

  position: absolute;
  top: -2px;
  left: -20px;
}

.name1 {
  display: inline-block;
  font-size: calc(var(--spc) * 3.5);
  font-weight: normal;
  letter-spacing: 0.29px;
  line-height: 1;
  margin-bottom: calc(var(--spc) * 1);
  padding: 0;
  color: #494949;
}
.name2 {
  display: block;
  font-size: calc(var(--spc) * 3.5);
  font-weight: bold;
  letter-spacing: 0.29px;
  line-height: calc(var(--spc) * 5);
}

.account-popover .MuiPopover-paper {
  overflow: visible;
}
.account-popover .MuiPopover-paper:before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--grey-light);
  position: absolute;
  top: -8px;
  right: 10%;
}
.account-popover .MuiPopover-paper:after {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  position: absolute;
  top: -7px;
  right: 10%;
}
